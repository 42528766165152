import {
    API_URL_PROD,
    CDN_URL_PROD,
    REPLICA_URL_PROD,
    ASSET_MANAGER_PROD,
    EDITOR_URL_PROD,
} from '../../shared/types/constants.types';

export const environment = {
    env: 'prod',
    production: true,
    apiUrl: API_URL_PROD,
    cdnUrl: CDN_URL_PROD,
    replicaUrl: REPLICA_URL_PROD,
    assetManagerUrl: ASSET_MANAGER_PROD,
    spotEditorUrl: EDITOR_URL_PROD,
};
